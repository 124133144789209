export const OPPORTUNITY_STATUS = {
    initial: {
        value: 'initial',
        label: 'Inicial',
    },
    pending: {
        value: 'pending',
        label: 'Pendiente',
    },
    proccess: {
        value: 'proccess',
        label: 'En proceso',
    },
    qualified: {
        value: 'qualified',
        label: 'Cualificada',
    },
    qualified_error: {
        value: 'qualified_error',
        label: 'Error al Cualificar',
    },
    qualified_pending: {
        value: 'qualified_pending',
        label: 'Pendiente Cualificar',
    },
    repre_vol: {
        value: 'repre_vol',
        label: 'Representación voluntaria',
    },
    repre_vol_error: {
        value: 'repre_vol_error',
        label: 'Error en Repre. Voluntario',
    },
    bonus_managment_pending: {
        value: 'bonus_managment_pending',
        label: 'Gestión de bono pendiente',
    },
    bonus_managment_pending_antiquity: {
        value: 'bonus_managment_pending_antiquity',
        label: 'Gestión de bono pendiente-Antigüedad',
    },
    bonus_managment_pending_sent: {
        value: 'bonus_managment_pending_sent',
        label: 'Gestión de bono enviado pendiente',
    },
    bonus_managment_sent: {
        value: 'bonus_managment_sent',
        label: 'Gestión de bono enviado',
    },
    approved: {
        value: 'approved',
        label: 'Aprobado',
    },
    correct: {
        value: 'correct',
        label: 'Subsanar',
    },
    denied: {
        value: 'denied',
        label: 'No Admite Presentación',
    },
    closed: {
        value: 'closed',
        label: 'Cerrada',
    },
    lost: {
        value: 'lost',
        label: 'Perdida',
    },
    re_requested: {
        value: 're_requested',
        label: 'Resolicitada',
    },
}

export const OPPORTUNITY_VERSIONS = [
    { label: 'Versión 1', value: 'v1' },
    { label: 'Versión 2', value: 'v2' },
    { label: 'Versión 3', value: 'v3' },
]
